<template>
  <section>
    <!-- Titulo -->
    <!--  Componente dinamico con props title del tipo string  -->
    <title-header-component title="Listado de proveedores sancionados" />

    <v-row class="d-flex justify-center justify-md-start">
      <!-- Filtro de busqueda por proveedor-->
      <v-col cols="12" sm="6" lg="4">
        <!--  Componente estatico que realiza una peticion del listado de proveedores sancionados con filtro de nombre -->
        <filter-list-prov-sacion-component />
      </v-col>

      <v-col cols="12">
        <!-- Tabla de proveedores sancionados -->
        <!-- 
             Componente dinamico con 
            props:
                headers del tipo array, 
                items del tipo array, 
                paginacion del tipo number ( hace referencia a las props page y per_page ...),
                mobile-breakpoint del tipo string 
        -->
        <data-table-component
          :headers="HEADERS_CONST"
          :items="list_prov_sanc_st"
          :total_registros="total_rows_cmp"
          @paginar="PaginateFtn"
          mobile-breakpoint="775"
          v-models:pagina="page_cmp"
          v-models:select="cant_per_page_cmp"
        >
          <!-- Slot de acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!--  Boton con funcion SeeDetailFtn que recibe el id del item como parametro -->
                <v-btn
                  @click="SeeDetailFtn(item.id)"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-eye'" />
                </v-btn>
              </template>
              <span v-text="'Ver detalle del proveedor'" />
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
    
    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>

    <InformacionSancionComponent ref="modalSancion"
      :modalSancion = "modalSancion"
      @cerrarModal = "closeModal"
      :detalleSancionProp="detalle_sancion"
      @registroEliminado="SeeDetailFtn"
      @recargarLista="FetchListProvSancionAc"
      />

  </section>
</template>

<script>
// Importaciones locales
import TitleHeaderComponent from "./components/TitleHeaderComponent.vue";
import FilterListProvSacionComponent from "./components/FilterListProvSacionComponent.vue";
import InformacionSancionComponent from "./components/InformacionSancionComponent.vue";
// Importaciones globales
import DataTableComponent from "../../components/DataTableComponent.vue";

// Importaciones de librerias
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    TitleHeaderComponent,
    FilterListProvSacionComponent,
    DataTableComponent,
    InformacionSancionComponent,
  },

  data: () => ({
    modalSancion: false,
    detalle_sancion: null,
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Nombre proveedor",
        value: "nombre",
      },
      {
        align: "center",
        text: "Sanciones activas",
        value: "sanciones_activas",
      },
      {
        align: "center",
        text: "Tiempo (días)",
        value: "tiempo",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
      },
    ],
  }),

  computed: {
    // Mapeo de estados
    ...mapState("sancionesStore", ["list_prov_sanc_st"]),
    ...mapState({
      cant_per_page_cmp: (state) =>
        state?.sancionesStore?.list_prov_sanc_pag_st?.per_page,
      page_cmp: (state) => state?.sancionesStore?.list_prov_sanc_pag_st?.page,
      total_rows_cmp: (state) =>
        state?.sancionesStore?.list_prov_sanc_pag_st?.total_rows,
    }),
  },

  methods: {
    // Mapeo de mutaciones
    ...mapMutations("sancionesStore", [
      "resetListProvMt",
      "resetListProvPagMt",
    ]),

    // Mapeo de acciones
    ...mapActions("sancionesStore", ["FetchListProvSancionAc"]),

    //Cerrar el modal
    closeModal() {
      this.modalSancion = false;
    },

    // Funciones asincronas
    //  Funcion que recibe los filtros de paginacion y realiza la peticion de listado de proveedores sancionados
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;
      await this.FetchListProvSancionAc({
        page: pagina,
        per_page: cantidad_por_pagina,
      });
    },
    SeeDetailFtn(id_proveedor_param) {
      this.modalSancion = true;
      this.id_proveedor = id_proveedor_param;
      this.performAction = true;
      this.showProveedorSancion(id_proveedor_param);
    },
    //Obtener el detalle del registro de la sancion
    async showProveedorSancion(idProveedor) {
      const response = await this.services.Sanciones.reqGetDetailProv(idProveedor);

      if (response?.status === 200) {
        this.detalle_sancion = response.data;
      }
      return this.detalle_sancion;
    },
  },

  created() {
    //  Llamada a la funcion FetchListProvSancionAc al crear el componente para cargar el listado de proveedores sancionados
    const filters_param = {
      page: 1,
      per_page: 10,
    };

    this.FetchListProvSancionAc(filters_param);
  },

  beforeDestroy() {
    //  Llamada a las funciones resetListProvMT y resetListProvPagMT al destruir el componente para resetear el estado de la lista de proveedores
    this.resetListProvMt();
    this.resetListProvPagMt();
  },
};
</script>
<!-- Vista de listado de proveedores sancionados -->
