<template>
  <!--  La funcion de busqueda trabaja con la variable search_prov_var a traves del evento keyup -->
  <v-text-field
    @keyup="ObserverInputFtn"
    append-icon="mdi-magnify"
    hide-details
    label="Proveedor, nombre comercial o documento"
    outlined
    v-model="search_prov_var"
  />
</template>

<script>
// Importaciones de librerias
import { mapActions } from "vuex";

export default {
  data: () => ({
    // variables
    search_prov_var: null,
    timer_var: null,
  }),

  methods: {
    // Mapeo de acciones
    ...mapActions("sancionesStore", ["FetchListProvSancionAc"]),

    //  Funcion que limpia el timer
    CleanTimerFtn() {
      if (this.timer_var !== null) clearInterval(this.timer_var);
    },

    //  Funcion que se ejecuta cada vez que se presiona una tecla en el input de busqueda
    ObserverInputFtn() {
      this.CleanTimerFtn();
      this.timer_var = setInterval(this.SearchProviderFtn, 500);
    },

    //  Funcion que realiza la solicitud de busqueda de proveedores, ademas de mostrar y ocultar el loader ( prototype global )
    async SearchProviderFtn() {

      this.CleanTimerFtn();
      const filters_scp_var = {
        page: 1,
        per_page: 10,
        nombre: this.search_prov_var ? this.search_prov_var : undefined,
      };

      //  Se realiza la solicitud de busqueda de proveedores ( a traves de la accion FetchListProvSancionAc )
      this.FetchListProvSancionAc(filters_scp_var);
      this.$emit('recargarLista');
    },
  },
};
</script>
